import React from "react";
import { Outlet, Link } from "react-router-dom";
import SelectedMenuContext from "../contexts/menuContext";

import PropTypes from "prop-types";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Menu as MenuIcon,
  MenuBook as MenuBookIcon,
  Create as CreateIcon,
  People as PeopleIcon,
} from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: theme.palette.background.default,
    overflow: "clip",
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: theme.palette.primary.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    color: "white",
  },
  menuTitle: {
    color: "white",
    fontFamily: "Lobster",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: `linear-gradient(218deg, ${theme.palette.primary.main} 30%,rgb(207 46 46) 100%)`, // Gradient using primary and a slightly lighter red
    color: "white",
    overflowY: "clip",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItem: {
    borderRadius: "8px",
    margin: theme.spacing(1, 2),
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
  selectedListItem: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
  },
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState("/");

  const handleMenuClick = (menuText) => {
    setSelectedMenu(menuText);
    setMobileOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const linkList = [
    { link: "recipe-book", sidebarText: "Recipe Book", icon: <MenuBookIcon /> },
    { link: "write-recipe", sidebarText: "Write Recipe", icon: <CreateIcon /> },
    { link: "friends", sidebarText: "Friends", icon: <PeopleIcon /> },
  ];

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider style={{ backgroundColor: "rgba(255,255,255,0.3)" }} />
      <List>
        {linkList.map((el) => (
          <ListItem
            button
            key={el.sidebarText}
            component={Link}
            to={"/app/" + el.link}
            onClick={() => handleMenuClick(el.link)}
            className={`${classes.listItem} ${
              selectedMenu === el.link ? classes.selectedListItem : ""
            }`}
          >
            <ListItemIcon style={{ color: "white" }}>{el.icon}</ListItemIcon>
            <ListItemText primary={el.sidebarText} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <SelectedMenuContext.Provider value={{ selectedMenu, setSelectedMenu }}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap className={classes.menuTitle}>
              {linkList.find((item) => item.link === selectedMenu)
                ?.sidebarText || "RecipeFox"}
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="menu navigation">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{ paper: classes.drawerPaper }}
              ModalProps={{ keepMounted: true }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{ paper: classes.drawerPaper }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Outlet />
        </main>
      </div>
    </SelectedMenuContext.Provider>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};

export default ResponsiveDrawer;
