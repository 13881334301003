import axios from "axios";

export default class ApiManager {
  static async request(method, url, body = null, isFormData = false) {
    try {
      const jwtToken = window.localStorage.getItem("jwtToken");

      let config = {
        method,
        url,
        headers: {
          Authorization: jwtToken,
        },
      };

      if (!isFormData) {
        config.headers["Content-Type"] = "application/json";
        config.data = body ? JSON.stringify(body) : null; // Ensure valid JSON
      } else {
        config.data = body; // FormData should not be stringified
      }

      const response = await axios(config);
      return response.data; // Always return response data
    } catch (error) {
      return this.handleError(error);
    }
  }

  static handleError(error) {
    let errorMessage = "An unexpected error occurred";

    if (error.response) {
      errorMessage =
        error.response.data?.message || `Error ${error.response.status}`;
    } else if (error.request) {
      errorMessage = "No response from server. Please check your connection.";
    } else {
      errorMessage = error.message;
    }

    console.error("API Error:", errorMessage);
    return { error: true, message: errorMessage };
  }

  static loadAllRecipes = async (userId) =>
    this.request("GET", `/api/recipe/all/${userId}`);

  static loadRecipe = async (recipeUUID) =>
    this.request("GET", `/api/recipe/${recipeUUID}`);

  static deleteRecipe = async (recipeUUID) =>
    this.request("POST", `/api/recipe/delete/${recipeUUID}`, {});

  static createRecipe = async (values) => {
    let data = new FormData();
    data.append("recipeImage", values.recipeImage);
    data.append("name", values.name);
    data.append("description", values.recipeText);
    data.append("recipeCooked", values.recipeCooked);
    data.append("cookingTime", values.cookingTime);

    return this.request("POST", `/api/recipe/create`, data, true);
  };

  static updateRecipe = async (recipeUUID, values) => {
    let data = new FormData();
    data.append("recipeImage", values.recipeImage);
    data.append("name", values.name);
    data.append("description", values.recipeText);
    data.append("recipeCooked", values.recipeCooked);
    data.append("cookingTime", values.cookingTime);

    return this.request("POST", `/api/recipe/update/${recipeUUID}`, data, true);
  };

  static loadFriends = async () => this.request("GET", `/api/user/friends`);

  static async login(email, password) {
    const res = await this.request("POST", `/api/authentication/login`, {
      email,
      password,
    });

    console.log("Login response:", res);

    if (res.error) {
      return res; // Handle login error properly
    }

    if (res.jwtToken && res.userId) {
      window.localStorage.setItem("jwtToken", res.jwtToken);
      window.localStorage.setItem("userId", res.userId);
      return { success: true };
    } else {
      return {
        error: true,
        message: "Did not receive valid login data from server.",
      };
    }
  }

  static async register(email, username, password, inviteCode) {
    const res = await this.request("POST", `/api/authentication/register`, {
      username,
      email,
      password,
      inviteCode,
    });

    if (res.error) {
      return res;
    }

    if (res.jwtToken && res.userId) {
      window.localStorage.setItem("jwtToken", res.jwtToken);
      window.localStorage.setItem("userId", res.userId);
      return { success: true };
    } else {
      return {
        error: true,
        message: "Did not receive valid registration data from server.",
      };
    }
  }

  static async refreshToken() {
    const res = await this.request("POST", `/api/authentication/refresh`);

    if (res.error) {
      console.error("Token refresh failed:", res.message);
      return res;
    }

    if (res.jwtToken) {
      window.localStorage.setItem("jwtToken", res.jwtToken);
      return { success: true };
    } else {
      return { error: true, message: "Did not receive a new token." };
    }
  }
}
