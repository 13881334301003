import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import TimerIcon from "@material-ui/icons/Timer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ApiManager from "../utils/ApiManager";
import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation";

import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import ConfirmationDialog from "../components/ConfirmationDialog/ConfirmationDialog";

const queryString = require("query-string");

export default function Recipe() {
  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      display: "flex",
    },
    recipeTitle: {
      fontFamily: "Lobster",
      fontWeight: "lighter",
      marginBottom: "35px",
    },
    recipeText: {
      width: "90%",
      borderStyle: "dashed",
      padding: "4%",
    },
    recipeImageSmall: {
      width: "60%",
      maxHeight: "50%",
    },
    recipeImageLarge: {
      width: "85%",
      maxHeight: "50%",
    },
    deleteButton: {
      background: theme.palette.error.main,
      "&:hover": {
        background: theme.palette.error.main,
      },
      marginTop: "20px",
      color: theme.palette.offWhite.main,
    },
    editButton: {
      background: "#416970",
      height: "50px",
      marginLeft: "20px",
      marginBottom: "0px",
    },
    recipeHeader: {
      display: "flex",
      alignItems: "baseline",
    },
    cookingTimeWrapper: {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
      marginTop: "15px",
    },
  });

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [recipe, setRecipe] = React.useState(null);
  const [recipeUUID, setRecipeUUID] = React.useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  // Function to load a recipe
  const handleLoadRecipe = async () => {
    try {
      setLoading(true);
      const res = await ApiManager.loadRecipe(recipeUUID);

      if (res.error) {
        setErrorMessage(res.message);
        setLoading(false);
        return;
      }

      setRecipe(res);
      setLoading(false);
    } catch (error) {
      setErrorMessage("Failed to load recipe.");
      setLoading(false);
    }
  };

  // Function to delete a recipe
  const handleDeleteRecipe = async () => {
    setLoading(true);
    const res = await ApiManager.deleteRecipe(recipeUUID);

    if (res.error) {
      setErrorMessage(res.message);
      setLoading(false);
      return;
    }

    navigate("/app/recipe-book"); // Navigate only if the delete was successful
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  // Load the recipe when the UUID changes
  React.useEffect(() => {
    if (recipeUUID) {
      handleLoadRecipe();
    }
  }, [recipeUUID]);

  // Extract recipe UUID from URL parameters
  React.useEffect(() => {
    const parsed = queryString.parse(location.search);
    setRecipeUUID(parsed.recipeUUID);
  }, [location]);

  return (
    <div>
      {loading ? <LoadingAnimation /> : null}

      {recipe && (
        <>
          <h1 className={classes.recipeTitle}>{recipe.name}</h1>
          <img
            className={`${
              matches ? classes.recipeImageSmall : classes.recipeImageLarge
            }`}
            src={
              recipe.recipeImages && recipe.recipeImages.length > 0
                ? recipe.recipeImages[0].image_src
                : ""
            }
          />
          <div className={classes.recipeHeader}>
            <h2>RECIPE</h2>
            <Button
              variant="contained"
              className={classes.editButton}
              onClick={() => navigate(`/app/edit-recipe?recipe=${recipe.uuid}`)}
            >
              <EditIcon style={{ color: theme.palette.offWhite.main }} />
            </Button>
          </div>
          <div className={classes.cookingTimeWrapper}>
            <TimerIcon />
            {"Cooking time: "}{" "}
            {recipe.cooking_time ? recipe.cooking_time : "--"} {" minutes"}
          </div>
          <div className={classes.recipeText}>
            {recipe.description
              ? parse(recipe.description)
              : "No description available"}
          </div>

          <Button
            variant="contained"
            className={classes.deleteButton}
            onClick={() => setDeleteDialogOpen(true)}
          >
            {"Delete"}
          </Button>

          <ConfirmationDialog
            isOpen={deleteDialogOpen}
            onClose={handleDialogClose}
            onConfirm={handleDeleteRecipe}
          />
        </>
      )}

      {/* Snackbar for displaying errors */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
      />
    </div>
  );
}
