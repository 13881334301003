import React from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import RecipeGridItem from "../components/RecipeGridItem.jsx";
import ApiManager from "../utils/ApiManager";
import qs from "qs";

import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  noHorizontalScroll: {
    overflow: "hidden!important",
    "& a:link": {
      textDecoration: "none",
    },
  },
  endMessage: {
    textAlign: "center",
    marginTop: "20px",
  },
}));

function RecipeBook(props) {
  const ownUserId = React.useMemo(() => {
    return window.localStorage.getItem("userId");
  }, []);

  const classes = useStyles();
  const [recipes, setRecipes] = React.useState([]);
  const [moreRecipes, setMoreRecipes] = React.useState(true);
  const [userId, setUserId] = React.useState(null);

  const handleLoadRecipes = React.useCallback(async () => {
    if (userId) {
      console.log("Loading recipes for user", userId);
      const loadedRecipes = await ApiManager.loadAllRecipes(userId);
      setRecipes(recipes.concat(loadedRecipes));
      setMoreRecipes(false);
    }

    return;
  }, [userId]);

  React.useEffect(() => {
    if (qs.parse(window.location.search, { ignoreQueryPrefix: true }).user) {
      const id = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      }).user;
      setUserId(id);
    } else {
      setUserId(ownUserId);
    }
  }, []);

  React.useEffect(() => {
    handleLoadRecipes();
  }, [userId]);

  return (
    <InfiniteScroll
      className={classes.noHorizontalScroll}
      dataLength={recipes.length} //This is important field to render the next data
      next={handleLoadRecipes}
      hasMore={moreRecipes}
      loader={<h4>Loading...</h4>}
      endMessage={
        <p className={classes.endMessage}>
          <b>You have seen all available recipes</b>
        </p>
      }
    >
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        spacing={3}
      >
        {recipes.map((recipe, i) => (
          <RecipeGridItem recipe={recipe} key={i} />
        ))}
      </Grid>
    </InfiniteScroll>
    // <Button color="secondary" onClick={handleLoadRecipes}>Load</Button>
  );
}

export default RecipeBook;
